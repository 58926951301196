<template>
  <div class="list-user">
    <div class="list-user-table px-0">
      <div class="mb-2 d-flex justify-content-between cpx-4 align-items-center">
        <span class="w-75 font-title p-0">
          ユーザー購入履歴：{{
            infoUser.lastName
              ? infoUser.lastName
              : "" + " " + infoUser.firstName
              ? infoUser.firstName
              : ""
          }}
        </span>
        <div class="w-25 py-3">
          <button
            v-on:click="gotoDashboard()"
            class="float-right button-back-dashboard back-btn"
          >
            戻る
          </button>
        </div>
      </div>
      <div class="row d-flex mx-0">
        <label class="col-sm-12 font-weight-bold cp-label">性</label>
        <div class="cp-4 w-100">
          <input
            class="form-control col-sm-12"
            v-model="infoUser.lastName"
            disabled
          />
        </div>
      </div>
      <div class="row d-flex mx-0">
        <label class="col-sm-12 font-weight-bold cp-label">名</label>
        <div class="cp-4 col-sm-12">
          <input
            class="form-control col-sm-12"
            v-model="infoUser.firstName"
            disabled
          />
        </div>
      </div>
      <div class="row d-flex mx-0">
        <label class="col-sm-12 font-weight-bold cp-label">
          メールアドレス
        </label>
        <div class="cp-4 col-sm-12">
          <input
            class="form-control col-sm-12"
            v-model="infoUser.email"
            disabled
          />
        </div>
      </div>
      <div class="row d-flex mx-0">
        <label class="col-12 font-weight-bold cp-label">購入日時</label>
        <div class="row-input cp-4 pr-1">
          <div class="fd-admin-listproperty-card-filter filter-date">
            <date-picker
              name="date"
              v-model="infoUser.fromTimeBuy"
              :config="optionsDate"
              autocomplete="off"
              style="margin-bottom: 1rem"
              placeholder="日付を選択"
            ></date-picker>
          </div>
        </div>
        <span class="custom-span mt-2" style="font-size: 20px">~</span>
        <div class="row-input cp-4 pl-1">
          <div class="fd-admin-listproperty-card-filter filter-date">
            <date-picker
              name="date"
              v-model="infoUser.toTimeBuy"
              :config="optionsDate"
              autocomplete="off"
              style="margin-bottom: 1rem"
              placeholder="日付を選択"
            ></date-picker>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4 mb-4">
        <b-button
          v-on:click.prevent="getListPurchase()"
          variant="info"
          class="mr-4"
          >絞り込み検索</b-button
        >
        <b-button
          variant="secondary"
          class="mr-4"
          v-on:click="resetFieldSearch()"
          >リセット</b-button
        >
      </div>
      <span class="w-75 font-title cpx-4 pb-0">定期購読中コンテンツ</span>
      <Tables
        :items="purchaseHistorySubscription.data"
        :fields="fieldsSubscription"
        :itemsPerPageSelect="{}"
        class="cpx-4"
      >
        <template v-slot:id="{ index }">
          <td>{{ index + 1 }}</td>
        </template>
        <template v-slot:created_at="{ item }">
          <td>
            <div v-if="item.created_at !== null">
              {{
                new Date(item.created_at).toLocaleString("ja-JP", {
                  timeZone: "Asia/Tokyo",
                })
              }}
            </div>
          </td>
        </template>
        <template v-slot:action="{ item }">
          <td class="d-flex align-items-center justify-content-around">
            <div>
              <Download
                :idOrder="item.order_id"
                :contentTitle="item.content_title"
                :isAdmin="true"
                :isSubscription="true"
              />
            </div>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="purchaseHistorySubscription.total"
      >
        <p class="font-weight-bold">
          {{ purchaseHistorySubscription.total }}件中{{
            purchaseHistorySubscription.data.length
          }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="purchaseHistorySubscription.last_page"
      >
        <CPagination
          v-if="purchaseHistorySubscription.total"
          :activePage.sync="pageSubscription"
          :pages="purchaseHistorySubscription.last_page"
          align="center"
        />
        <div class="custom-selectLimitPage cpx-4">
          <b-form-select
            v-model="limitSubscription"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPageSubscription"
            @change="changeLimitPageSubscription()"
          ></b-form-select>
        </div>
      </div>
      <span class="w-75 mt-5 font-title cpx-4">購入・所持済みコンテンツ</span>
      <Tables
        :items="purchaseHistoryOneTime.data"
        :fields="fieldsOneTime"
        :itemsPerPageSelect="{}"
        class="cpx-4"
      >
        <template v-slot:id="{ index }">
          <td>{{ index + 1 }}</td>
        </template>
        <template v-slot:created_at="{ item }">
          <td>
            <div v-if="item.created_at !== null">
              {{
                new Date(item.created_at).toLocaleString("ja-JP", {
                  timeZone: "Asia/Tokyo",
                })
              }}
            </div>
          </td>
        </template>
        <template v-slot:action="{ item }">
          <td>
            <Download
              :idOrder="item.order_id"
              :contentTitle="item.content_title"
              :isAdmin="true"
            />
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="purchaseHistoryOneTime.total"
      >
        <p class="font-weight-bold">
          {{ purchaseHistoryOneTime.total }}件中{{
            purchaseHistoryOneTime.data.length
          }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="purchaseHistoryOneTime.last_page"
      >
        <CPagination
          v-if="purchaseHistoryOneTime.total"
          :activePage.sync="pageOneTime"
          :pages="purchaseHistoryOneTime.last_page"
          align="center"
        />
        <div class="custom-selectLimitPage cpx-4">
          <b-form-select
            v-if="purchaseHistoryOneTime.total"
            v-model="limitOneTime"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPageOneTime"
            @change="changeLimitPageOneTime()"
          ></b-form-select>
        </div>
      </div>
    </div>
    <!-- modal confirm cancel -->
    <div>
      <b-modal id="confirm-cancel" hide-header hide-footer>
        <div class="d-block text-center mb-5 position-relative">
          <h5>解約の注意点</h5>
          <button
            type="button"
            aria-label="Close"
            class="close"
            @click="$bvModal.hide('confirm-cancel')"
            style="position: absolute; left: 0; top: 0"
          >
            ×
          </button>
        </div>
        <div class="d-flex justify-content-center">
          <div class="mb-3">
            <div>
              <span style="font-size: 18px">①</span> 「{{
                contentSubscription.content_title
              }}」のコンテンツが<span style="text-decoration: underline"
                >すべて見れなくなります</span
              >。
            </div>
            <div>
              <span style="font-size: 18px">②</span>
              <span style="text-decoration: underline"
                >付属したすべての特典</span
              >が見れなくなります。
            </div>
            <div>
              <span style="font-size: 18px">③</span>
              再度申し込む時、価格が違うことがあります。その場合は<span
                style="text-decoration: underline"
                >最新の価格での申し込み</span
              >となりますのでご注意ください。
            </div>
            <div class="text-center mt-4 mb-3">本当によろしいですか？</div>
          </div>
        </div>
        <div class="justify-content-center d-flex align-items-center">
          <b-button
            class="btn back-btn"
            style="
              margin-right: 40px;
              background: #00a2ff;
              color: #fff;
              min-width: 110px;
              padding: 12px 0;
              border-radius: 0;
              border: 0;
            "
            @click="$bvModal.hide('confirm-cancel')"
          >
            <span>戻る</span>
          </b-button>
          <div
            @click="
              cancelSubscriptionPayment(
                contentSubscription.author_id,
                contentSubscription.order_id,
                contentSubscription.payment_type
              )
            "
            class="text-center btn-cancel-text"
          >
            <b-spinner v-if="isLoadingCancel" small></b-spinner>
            このまま解約する（確認画面なし）
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { Constants } from "../../utils/constants";
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { mapActions, mapGetters } from "vuex";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import $ from "jquery";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";
import Download from "../pages/pagePrintInvoice";

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle",
  },
});
export default {
  name: "ListUser",
  data() {
    return {
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      userId: this.$route.params.id,
      userType: localStorage.getItem(Constants.USER_TYPE_ADMIN),
      infoUser: {
        firstName: "",
        lastName: "",
        email: "",
        fromTimeBuy: "",
        toTimeBuy: "",
      },
      fieldsSubscription: tableFields.PURCHASE_USER_SUBSCRIPTION,
      listLimitRecordPageSubscription: [
        { value: 10, text: "10" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      limitSubscription: 10,
      pageSubscription: 1,
      fieldsOneTime: tableFields.PURCHASE_USER_ONE_TIME,
      listLimitRecordPageOneTime: [
        { value: 10, text: "10" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      limitOneTime: 10,
      pageOneTime: 1,
      optionsDate: {
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false,
        showClear: false,
        showClose: false,
        // minDate: new Date() + "",
        locale: "ja",
      },
      contentSubscription: {
        author_id: "",
        order_id: "",
        payment_type: "",
        content_title: "",
      },
      isLoadingCancel: false,
    };
  },
  components: {
    Tables,
    Download,
  },
  created() {
    if (this.$route.query.date_from) {
      this.infoUser.fromTimeBuy = this.$route.query.date_from;
    }
    if (this.$route.query.date_to) {
      this.infoUser.toTimeBuy = this.$route.query.date_to + " 23:59:59";
    }
    this.getListPurchaseSubscription(1);
    this.getListPurchaseOneTime(1);
    const formData = {
      id: this.userId,
      shop_id: this.shop_id,
    };
    this.getUserById(formData);
  },
  computed: {
    ...mapGetters([
      "purchaseHistorySubscription",
      "purchaseHistoryOneTime",
      "message",
      "success",
      "error",
      "userDetail",
    ]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        this.isLoadingCancel = false;
        this.$bvModal.hide("confirm-cancel");
        this.getListPurchaseSubscription(this.pageSubscription);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
        this.isLoadingCancel = false;
      }
    },
    userDetail() {
      if (Object.keys(this.userDetail).length) {
        this.infoUser.firstName = this.userDetail.first_name;
        this.infoUser.lastName = this.userDetail.last_name;
        this.infoUser.email = this.userDetail.email;
      }
    },
    purchaseHistorySubscription() {
      this.pageSubscription = this.purchaseHistorySubscription.current_page;
    },
    purchaseHistoryOneTime() {
      this.pageOneTime = this.purchaseHistoryOneTime.current_page;
    },
    pageSubscription() {
      this.getListPurchaseSubscription(this.pageSubscription);
    },
    pageOneTime() {
      this.getListPurchaseOneTime(this.pageOneTime);
    },
  },
  methods: {
    ...mapActions({
      getPurchaseHistorySubscriptionAdmin:
        "getPurchaseHistorySubscriptionAdmin",
      cancelSubscriptionAdmin: "cancelSubscriptionAdmin",
      getPurchaseHistoryOneTimeAdmin: "getPurchaseHistoryOneTimeAdmin",
      getUserById: "getUserById",
    }),
    getListPurchaseSubscription(page) {
      if (page === undefined) {
        page = this.pageSubscription;
      }
      const formData = {
        user_type: this.userType,
        page: page,
        data: {
          user_id: this.userId,
          shop_id: this.shop_id,
          start_time: this.infoUser.fromTimeBuy,
          end_time: this.infoUser.toTimeBuy,
          limit: this.limit,
        },
      };
      this.getPurchaseHistorySubscriptionAdmin(formData);
    },
    getListPurchaseOneTime(page) {
      if (page === undefined) {
        page = this.pageOneTime;
      }
      const formData = {
        user_type: this.userType,
        page: page,
        data: {
          user_id: this.userId,
          shop_id: this.shop_id,
          start_time: this.infoUser.fromTimeBuy,
          end_time: this.infoUser.toTimeBuy,
          limit: this.limit,
        },
      };
      this.getPurchaseHistoryOneTimeAdmin(formData);
    },
    changeLimitPageSubscription() {
      this.getListPurchaseSubscription(1);
    },
    changeLimitPageOneTime() {
      this.getListPurchaseOneTime(1);
    },
    gotoDashboard() {
      this.$router.go(-1);
    },
    resetFieldSearch() {
      this.infoUser.fromTimeBuy = "";
      this.infoUser.toTimeBuy = "";
      const formData = {
        page: 1,
        data: {
          user_id: this.userId,
          shop_id: this.shop_id,
          start_time: this.infoUser.fromTimeBuy,
          end_time: this.infoUser.toTimeBuy,
          limit: this.limit,
        },
      };
      this.getPurchaseHistorySubscriptionAdmin(formData);
      this.getPurchaseHistoryOneTimeAdmin(formData);
    },
    getListPurchase() {
      if (
        this.infoUser.fromTimeBuy &&
        this.infoUser.toTimeBuy &&
        this.infoUser.fromTimeBuy > this.infoUser.toTimeBuy
      ) {
        this.$toasted.error("該当日時の検索はできません。");
      } else {
        const formData = {
          page: 1,
          data: {
            user_id: this.userId,
            shop_id: this.shop_id,
            start_time: this.infoUser.fromTimeBuy,
            end_time: this.infoUser.toTimeBuy,
            limit: this.limit,
          },
        };
        this.getPurchaseHistorySubscriptionAdmin(formData);
        this.getPurchaseHistoryOneTimeAdmin(formData);
      }
    },
    cancelSubscriptionPayment(author_id, order_id, type) {
      if (!this.isLoadingCancel) {
        this.isLoadingCancel = true;
        const reg = {
          author_id: author_id,
          order_id: order_id,
          type: type === 2 ? "stripe" : "paypal",
          shop_id: this.shop_id,
        };
        this.cancelSubscriptionAdmin(reg);
      }
    },
    showModalConfirmCancel(value) {
      this.contentSubscription.author_id = value.author_id;
      this.contentSubscription.order_id = value.order_id;
      this.contentSubscription.payment_type = value.payment_type;
      this.contentSubscription.content_title = value.content_title;
      this.$bvModal.show("confirm-cancel");
    },
  },
};
</script>
<style lang="scss">
.btn-cancel:hover {
  cursor: pointer;
}
.btn-cancel-text {
  color: #999;
}
.btn-cancel-text:hover {
  cursor: pointer;
}
</style>
